import React from 'react';
import {useNavigate} from 'react-router-dom';
import {clearStorage, getItem} from '@/Helpers/localStorageHelpers';
import {callWithJwt, doLogoutRequest, refreshJwt} from '@/Helpers/jwtHelpers';
import {useInterval} from '@/Helpers/useInterval';
import {User} from "@/Areas/Customer/Data/UserTypes";

export enum LoggedInState {
    NotYetKnown = 'NOT_YET_KNOWN',
    LoggedIn = 'LOGGED_IN',
    LoggedOut = 'LOGGED_OUT',
}

export type UserContextType = {
    loggedInState: LoggedInState;
    user?: User;
    setUser: React.SetStateAction<User>;
    attemptLogout: () => Promise<void>;
    fetchAndSetUserData: () => Promise<boolean>;
    setLoggedInState: (state: LoggedInState) => React.SetStateAction<LoggedInState>;
};

export const UserContext = React.createContext({});
UserContext.displayName = 'UserContext';

export const UserProvider = (props: {children: React.JSX.Element[] | React.JSX.Element}): React.JSX.Element => {
    const [loggedInState, setLoggedInState] = React.useState<LoggedInState>(LoggedInState.NotYetKnown);
    const [user, setUser] = React.useState<User | undefined>(undefined);

    const navigate = useNavigate();

    const attemptLogout = (): Promise<any> => {
        return doLogoutRequest()
            .catch((reason) => {
                console.warn('Server side logout failed. Deleting client side data only', reason);
            })
            .finally(() => {
                setLoggedInState(LoggedInState.LoggedOut);
                clearStorage();
                setUser(undefined);
                navigate('/', {state: {logout: true}});
            });
    };

    const fetchAndSetUserData = async (): Promise<boolean> => {
        return callWithJwt<User>('/customers/self', 'GET')
            .then((result) => {
                if (!result.data || result.response.status !== 200) {
                    return Promise.reject(result);
                }

                setUser(result.data);
                return true;
            })
            .catch(async (reason) => {
                console.error('Could not fetch user', reason);
                await attemptLogout();
                return false;
        });
    };

    useInterval(
        (): void => {
            if (loggedInState !== LoggedInState.LoggedIn) {
                return;
            }
            void refreshJwt();
        },
        600000,
    );

    React.useEffect(() => {
        const fetchDataAndRefreshToken = async (): Promise<void> => {
            const token: string | undefined = getItem('jwt', undefined);
            if (!token) {
                setLoggedInState(LoggedInState.LoggedOut);
                return;
            }

            await fetchAndSetUserData();
            setLoggedInState(LoggedInState.LoggedIn);
        };

        void fetchDataAndRefreshToken();
    }, []);

    const value = {
        user,
        setUser,
        loggedInState,
        setLoggedInState,
        attemptLogout,
        fetchAndSetUserData,
    };

    return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextType => {
    const context = React.useContext(UserContext);
    if (Object.keys(context).length === 0) {
        console.error('UserContext is undefined. Was useUserContext called within a UserProvider?');
    }
    return context as UserContextType;
};
