import React, {ReactElement} from 'react';
import styled from 'styled-components';
import {Card, CardHeader} from '@mui/material';

const AuthContainerHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LessPaddedCardHeader = styled(CardHeader)`
    padding-top: 0;
    padding-bottom: 0;
`;


export const AuthContainerHeader = (props: any): ReactElement => {
    const {title} = props;
    return <AuthContainerHeaderContainer>
        {/* @ts-ignore */}
        <LessPaddedCardHeader title={title} component="h1" />
    </AuthContainerHeaderContainer>;
};

const AuthContainer = styled(Card)`
    font-family: sans-serif;
`;

export default AuthContainer;
