import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {FormiflyForm, UnpackedErrors} from 'formifly';
import styled from 'styled-components';
import {Link, useNavigate} from 'react-router-dom';
import {Alert, AlertColor, Button, Card, CardContent, CardHeader, Grid} from '@mui/material';
import {FormiflyMuiField} from '@common/butterfly-shared-react-library';
import {getBankAccountShape} from '@/Areas/BankAccount/Data/BankAccount';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import withLoginRequirement from '@/Components/withLoginRequirement';

const BankAccountCreation = (): React.JSX.Element => {
    const {t} = useTranslation(['bankAccount', 'common', 'formifly']);

    const [needsBIC, setNeedsBIC] = React.useState<boolean>(false);
    const [alert, setAlert] = React.useState({show: false, message: '', severity: 'info'});

    const bankAccountShape = getBankAccountShape(needsBIC);
    const navigate = useNavigate();

    const handleSubmit = (values: any, setErrors: React.Dispatch<React.SetStateAction<UnpackedErrors<typeof bankAccountShape>>>): void => {
        callWithJwt('/accounts', 'POST', values)
            .then(() => {
                navigate('/account/payment');
            })
            .catch((reason) => {
                if (reason.data.error) {
                    if (reason.data.error.code === 'invalid_secret') {
                        setAlert({show: true, message: 'error.invalid_secret', severity: 'error'});
                        setErrors({secret: t('error.invalid_secret_field')});
                    }
                    if (reason.data.error.code === 'validation_error') {
                        if (reason.data.error.data.field_errors.bic) {
                            setAlert({show: true, message: 'error.BIC_required', severity: 'error'});
                            setErrors({bic: t('error.BIC_required_field')});
                            setNeedsBIC(true);
                        }
                    }
                }
            });
    };

    return <>
        <Card>
            <CardHeader title={t('add')} />
            <CardContent>
                {alert.show && <PaddedGrid item xs={12}>
                    <Alert severity={alert.severity as AlertColor}>
                        <Trans t={t} ns="bankAccount">{alert.message}</Trans>
                    </Alert>
                </PaddedGrid>}
                <FormiflyForm shape={bankAccountShape} onSubmit={handleSubmit as any} t={t as any}>
                    <Grid container spacing={2}>
                        {needsBIC && <Grid item xs={12}>
                            <FormiflyMuiField name="bic" label={t('bankAccount:bic')} />
                        </Grid>}
                        <Grid item xs={12}>
                            <FormiflyMuiField name="name" label={t('bankAccount:name')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormiflyMuiField name="identifier" label={t('bankAccount:iban')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormiflyMuiField name="secret" label={t('bankAccount:secret')} help={t('bankAccount:secret_help')} wrapHelp />
                        </Grid>
                        <Grid item xs={12}>
                            <FormiflyMuiField name="internal_comment" label={t('bankAccount:internal_comment')} />
                        </Grid>
                        <Grid item xs={6}>
                            <Button color="primary" type="submit" variant="contained" fullWidth={true}>
                                {t('bankAccount:save')}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button to="/account/payment" component={Link} variant="contained" color="error" fullWidth>
                                {t('common:cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </FormiflyForm>
            </CardContent>
        </Card>
    </>;
};

const PaddedGrid = styled(Grid)`
    padding-bottom: 16px;
`;

const BankAccountCreationWithHOC = withLoginRequirement(BankAccountCreation);

export default BankAccountCreationWithHOC;
