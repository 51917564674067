import React from 'react';
import {Alert, Button, CardContent, CardHeader, Grid, Modal} from '@mui/material';
import {TFunction} from 'i18next';
import styled from 'styled-components';
import ModalBody from '@/Components/Layout/ModalBody';
import {BankAccount} from '@/Areas/BankAccount/Data/BankAccount';

type ConfirmBankAccountDeletionProps = {
    bankAccount?: BankAccount,
    t: TFunction<['common', 'bankAccount', 'error']>,
    error?: string,
    onClose: () => void,
    onDelete: () => void,
}

const ConfirmBankAccountDeletion = (props: ConfirmBankAccountDeletionProps): React.JSX.Element => {
    const {bankAccount, t, onClose, onDelete, error} = props;
    return <Modal open={Boolean(bankAccount)}
                  onClose={onClose}
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="confirm-deletion-modal-title"
                  aria-live="assertive">
        <ModalBody>
            <CardHeader title={t('bankAccount:delete')}/>
            <CardContent>
                {error && <SmolAlert severity="error">{t('bankAccount:error.' + error as any)}</SmolAlert>}
                {Boolean(bankAccount)
                    ? <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {t('bankAccount:delete_confirm', {which: bankAccount!.name === '' ? bankAccount!.identifier : bankAccount!.name})}
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth color="error" variant="contained"
                                    onClick={onDelete}>{t('common:delete')}</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth color="primary" variant="contained"
                                    onClick={onClose}>{t('common:cancel')}</Button>
                        </Grid>
                    </Grid>
                    : <p>Undefined.</p>}
            </CardContent>
        </ModalBody>
    </Modal>;
};

const SmolAlert = styled(Alert)`
    margin-bottom: 1rem;
`;

export default ConfirmBankAccountDeletion;
