import {useRoutes} from 'react-router-dom';
import React from 'react';
import NotFound from '@/Areas/NotFound/Views/NotFound';
import AccountOverview from '@/Areas/Customer/AccountOverview';
import Home from '@/Areas/Home/Views/Home';
import Connector from '@/Areas/Connector/Views/Connector';
import {globalConfig} from '@/Helpers/globalConfig';
import TransactionSearch from '@/Areas/Transaction/Views/TransactionSearch';
import TransactionReceipt from '@/Areas/Transaction/Views/TransactionReceipt';
import TransactionStarted from '@/Areas/Connector/Views/TransactionStarted';
import Auth from '@/Areas/Auth/Views/Auth';
import BankAccountOverview from '@/Areas/BankAccount/Views/BankAccountOverview';
import TransactionRedirection from '@/Areas/Transaction/Views/TransactionRedirection';
import BankAccountCreation from '@/Areas/BankAccount/Views/BankAccountCreation';
import BankAccountEdit from '@/Areas/BankAccount/Views/BankAccountEdit';

const Routes = (): React.ReactElement | null => {
    const routes = [
        {
            path: '/go/:connectorUid/trans/:transactionRequestUid',
            element: <TransactionStarted/>,
        },
        {
            path: '/go/:connectorUid',
            element: <Connector/>,
        },
        {
            path: '/account',
            element: <AccountOverview/>,
        },
        {
            path: '/search',
            element: <TransactionSearch/>,
        },
        {
            path: '/receipt/:uid',
            element: <TransactionReceipt/>,
        },
        {
            path: '/:uid/:urlToken',
            element: <TransactionRedirection/>,
        },
        {
            path: '/',
            element: <Home/>,
        },
        {
            path: '*',
            element: <NotFound/>,
        },
    ];
    if (globalConfig.enableLogin) {
        routes.push({
                path: '/login',
                element: <Auth/>,
            },
            {
                path: '/logout',
                element: <Home logout={true}/>,
            },
            {
                path: '/account/payment',
                element: <BankAccountOverview/>,
            },
            {
                path: '/account/payment/add',
                element: <BankAccountCreation/>,
            },
            {
                path: '/account/payment/:id/edit',
                element: <BankAccountEdit/>,
            });
    }

    return useRoutes(routes);
};

export default Routes;
