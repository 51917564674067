import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {AppBar, Button, Toolbar} from '@mui/material';
import {Menu as MenuIcon} from '@mui/icons-material';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import GiroEIcon from './GiroEIcon';
import {NavbarPopupMenu} from '@/Components/Layout/Common';
import NavMenu from '@/Components/Layout/NavMenu';

const StyledGiroe = styled(GiroEIcon)`
    height: 2rem;
    padding: 0.5rem 0;
`;

const FlexToolbar = styled(Toolbar)`
    display: flex;
`;

const GrowDiv = styled.div`
    flex-grow: 2;
`;

const GiroEAppBar = styled(AppBar)`
    border-bottom: 2px solid ${props => props.theme.palette.primary.main as string};
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
`;

const SmolMenuContainer = styled.div`
    @media (min-width: ${props => props.theme.breakpoints.values.md as string}px) {
        display: none;
    }
`;

const PayTHeaderImage = styled.img`
    width: 105px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
`;

const HorizontalNavMenuContainer = styled.div`
    @media (max-width: ${props => props.theme.breakpoints.values.md as string}px) {
        display: none;
    }
`;


const Header = (): React.JSX.Element => {
    const [smolMenuAnchor, setSmolMenuAnchor] = React.useState<Element>();

    const {t} = useTranslation(['common']);

    const [currentLogo, setCurrentLogo] = React.useState<React.JSX.Element>(() => {
        return <Link to="/" title={t('home_link')}>
            <StyledGiroe />
        </Link>;
    });

    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (document.URL.search('/search|receipt/') !== -1) {
            setCurrentLogo(<Link to="/" title={t('home_link')}>
                <PayTHeaderImage src="/img/pay-t/pay-t-logo.png" alt="Pay-T logo" />
            </Link>);
        } else {
            setCurrentLogo(<Link to="/" title={t('home_link')}>
                <StyledGiroe />
            </Link>);
        }
    }, [location]);

    React.useEffect(() => {
        closeSmolMenu();
    }, [window.location.pathname]);

    const openSmolMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (e.currentTarget !== undefined) {
            setSmolMenuAnchor(e.currentTarget);
        }
    };

    const closeSmolMenu = (): void => {
        setSmolMenuAnchor(undefined);
    };

    const handleSearchClick = (): void => {
        navigate('/search');
    };

    return <GiroEAppBar position="static" color="secondary" enableColorOnDark={true}>
        <FlexToolbar>
            {currentLogo}
            <GrowDiv />
            <HorizontalNavMenuContainer>
                <NavMenu isHorizontalMenu={true}
                         smolMenuAnchor={smolMenuAnchor}
                         closeSmolMenu={closeSmolMenu}
                         handleSearchClick={handleSearchClick}
                         t={t} />
            </HorizontalNavMenuContainer>
            <SmolMenuContainer>
                <Button
                    aria-haspopup="true"
                    aria-controls="smol-hamburger"
                    title={t('header.hamburger')}
                    onClick={openSmolMenu}>
                    <MenuIcon />
                </Button>
                <NavbarPopupMenu>
                    <NavMenu open={Boolean(smolMenuAnchor)}
                             smolMenuAnchor={smolMenuAnchor}
                             closeSmolMenu={closeSmolMenu}
                             handleSearchClick={handleSearchClick}
                             t={t} />
                </NavbarPopupMenu>
            </SmolMenuContainer>
        </FlexToolbar>
    </GiroEAppBar>;
};

export default Header;
