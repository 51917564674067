import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Face2, Login as LoginIcon} from '@mui/icons-material';
import {ListItemText} from '@mui/material';
import {LoggedInState, useUserContext} from '@/Contexts/User/UserContext';
import {LeftMenuItem, PrimaryColorListItemIcon, UnStyledLink} from '@/Components/MenuComponents';
import {getUserAvatarName} from '@/Areas/Customer/Helpers/CustomerHelpers';
import UserAvatar from '@/Areas/Customer/Components/UserAvatar';


const MyAccountMenuItem = (): ReactElement => {
    const {t} = useTranslation('common');
    const navigate = useNavigate();

    const {user, loggedInState} = useUserContext();

    const [userAvatarName, setUserAvatarName] = React.useState<string>('');

    React.useEffect(() => {
        if (user) {
            setUserAvatarName(getUserAvatarName(user.name));
        }
    }, [user]);

    const handleAccountSelectionClick = (): void => {
        navigate('/account');
    };

    const handleLoginClick = (): void => {
        navigate('/login');
    };


    return loggedInState === LoggedInState.LoggedIn
        ? <LeftMenuItem onClick={handleAccountSelectionClick}>
            {Boolean(userAvatarName)
                ? <PrimaryColorListItemIcon><UserAvatar>{userAvatarName}</UserAvatar></PrimaryColorListItemIcon>
                : <PrimaryColorListItemIcon><Face2 /></PrimaryColorListItemIcon>
            }
            <ListItemText>
                {t('account.my_account')}
            </ListItemText>
        </LeftMenuItem>
        : <LeftMenuItem onClick={handleLoginClick}>
            <PrimaryColorListItemIcon><LoginIcon /></PrimaryColorListItemIcon>
            <ListItemText><UnStyledLink to="/login">{t('header.login')}</UnStyledLink></ListItemText>
        </LeftMenuItem>;
};

export default MyAccountMenuItem;
